import './ChartCard.scss';
import React from 'react';

const ChartCard = ({ children, label, className }) => {

  return (
    <div className={`chart-card ${className ? className : '' }`}>
      {label && (
        <h3 className="chart-card-label">
          {label}
        </h3>
      )}
      {children}
    </div>
  );
};

export default ChartCard;
