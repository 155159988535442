import { LoadingSpinner } from '@parkhub/parkhub-ui';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Snackbar } from 'react-md';
import openSocket from 'socket.io-client';
import { ErrorOutlineIcon } from '../../assets/react-icons';
import { FilterBar, FilterChip } from '../../components';
import { checkAuth, getCookie, isNotEmpty, signOut } from '../../utils';
import './Overview.scss';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

const socket = openSocket(process.env.REACT_APP_LIVE_SERVICE_API);
const token = { '86553b39': getCookie('86553b39') };

const Overview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [metrics, setMetrics] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    socket.on('metrics.updated', payload => {
      checkLoggedIn();
      setMetrics(payload.metrics);
    });

    socket.on('metrics.new', payload => {
      checkLoggedIn();
      setMetrics(payload.metrics);
      setFilters(payload.filters);
      setTransactions(payload.transactions);
      setIsLoading(false);
    });

    socket.on('filters.updated', payload => {
      checkLoggedIn();
      setFilters(filters => {
        let LOTS = filters.lots;
        let EVENTS = filters.events;
        let LANDMARKS = filters.landmarks;

        if (payload.newlyAvailable.events.length) {
          EVENTS = EVENTS.concat(payload.newlyAvailable.events);
        }

        if (payload.newlyUnavailable.events.length) {
          EVENTS = _.reject(EVENTS, (item) => _.find(payload.newlyUnavailable.events, { id: item.id }));
        }

        if (payload.newlyAvailable.lots.length) {
          LOTS = LOTS.concat(payload.newlyAvailable.lots);
        }

        if (payload.newlyUnavailable.lots.length) {
          LOTS = _.reject(LOTS, (item) => _.find(payload.newlyUnavailable.lots, { id: item.id }));
        }

        if (payload.newlyAvailable.landmarks.length) {
          LANDMARKS = LANDMARKS.concat(payload.newlyAvailable.landmarks);
        }

        if (payload.newlyUnavailable.landmarks.length) {
          LANDMARKS = _.reject(LANDMARKS, (item) => _.find(payload.newlyUnavailable.landmarks, { id: item.id }));
        }

        return {
          events: EVENTS,
          lots: LOTS,
          landmarks: LANDMARKS
        };
      });
    });

    socket.on('metrics.transaction', payload => {
      checkLoggedIn();
      setTransactions(prevTransactions => {
        const index = _.findIndex(prevTransactions, { transaction_id: payload.transaction_id });

        if (index !== -1) {
          const newList = prevTransactions;
          newList.splice(index, 1, payload);

          return [...newList];

        } else {
          return [payload, ...prevTransactions];
        }
      });
    });

    socket.on('metrics.error', error => {
      console.log('metrics.error:', error);
      checkLoggedIn();
      setIsLoading(false);
      addToast(
        <span>
          <ErrorOutlineIcon width={20} color="#FF4F4F" />
          &nbsp; Seems to be taking longer than expected.
        </span>,
        'Dismiss'
      );
    });

    socket.emit('client.join', token);
  }, []);

  function formatFilterData(list) {
    return list.map(item => ({
      id: item.id,
      name: item.name,
      selected: item.selected
    }));
  }

  function handleApply(list, filterKey) {
    setIsLoading(true);
    checkLoggedIn();

    if (filterKey === 'lots') {
      const lotFilterData = {
        lots: formatFilterData(list),
        events: formatFilterData(filters.events),
        landmarks: formatFilterData(filters.landmarks)
      };

      socket.emit('client.updated', lotFilterData);
    }

    if (filterKey === 'events') {
      const eventFilterData = {
        events: formatFilterData(list),
        lots: formatFilterData(filters.lots),
        landmarks: formatFilterData(filters.landmarks)
      };

      socket.emit('client.updated', eventFilterData);
    }

    if (filterKey === 'landmarks') {
      const landmarksFilterData = {
        landmarks: formatFilterData(list),
        events: formatFilterData(filters.events),
        lots: formatFilterData(filters.lots)
      };

      socket.emit('client.updated', landmarksFilterData);
    }
  }

  function checkLoggedIn() {
    if (!checkAuth()) {
      signOut();
    }
  }

  function addToast(text, action) {
    setToasts((prevToasts) => {
      const toasts = prevToasts.slice();
      toasts.push({ text, action });
      return toasts;
    });
  };

  function dismissToast() {
    const [, ...restToasts] = toasts;
    setToasts(restToasts);
  };

  function scrubFilters(filters) {
    const newFilters = [...filters].map((item) => {
      delete item.__typename;
      return item;
    }).filter(value => Object.keys(value).length !== 0);

    return newFilters;
  }

  return (
    <div id="overview">
      <section className="top-action-bar">
        <div className="row collapse-sides">
          <div className="col-xs-12">
            <div className="action-container">
              {isNotEmpty(filters) &&
                <FilterBar>
                  {isNotEmpty(scrubFilters(filters.events)) &&
                    <FilterChip
                      label="Events"
                      id="overview-event-filter"
                      data={scrubFilters(filters.events)}
                      filterKey="events"
                      onApply={handleApply}
                    />
                  }
                  {isNotEmpty(scrubFilters(filters.lots)) &&
                    <FilterChip
                      label="Lots"
                      id="overview-lots-filter"
                      data={scrubFilters(filters.lots)}
                      filterKey="lots"
                      onApply={handleApply}
                    />
                  }
                  {isNotEmpty(scrubFilters(filters.landmarks)) &&
                    <FilterChip
                      label="Locations"
                      id="overview-landmarks-filter"
                      data={scrubFilters(filters.landmarks)}
                      filterKey="landmarks"
                      onApply={handleApply}
                    />
                  }
                </FilterBar>
              }
            </div>
          </div>
        </div>
      </section>
      <section className="content-container">
        <Section1 data={metrics} />
        <Section2 data={metrics} />
        <Section3 metrics={metrics} transactions={transactions} />
      </section>
      <Snackbar
        id="error-snackbar"
        toasts={toasts}
        autohide
        autohideTimeout={5000}
        onDismiss={dismissToast}
      />
      <LoadingSpinner show={isLoading} delay={1000} />
    </div>
  );
};

export default Overview;
