import React, { Fragment } from 'react';
import { DonutChart, EmptyChart } from '../';
import { titleCase, percentFormat } from '../../utils/';
import _ from 'lodash';

const TotalTransactions = (props) => {
  const data = props.data;
  const dataAvailable = data.value > 0;
  const colors = {
    cash: '#B5E77C',
    credit: '#5FE0C6',
    prepaid: '#29B6F6',
    exempt: '#4587E3',
    permit: '#673ab7'
  };

  let formattedData = [];
  let name = '';
  let centerValue = 0;

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      for (let x in colors) {
        if (data.hasOwnProperty(x)) {
          formattedArray.push({
            name: titleCase(x),
            y: data[x],
            color: colors[x],
            selected: true
          });
        };
      };

      return formattedArray;
    })();

    const highestValue = getHighestValue(formattedData);
    name = highestValue.name;
    centerValue = percentFormat((highestValue.y / data.value) * 100, 0);
  }

  function getHighestValue(list) {
    return _.maxBy(list, (o) => o.y);
  }

  return (
    <Fragment>
      {dataAvailable ?
        <DonutChart total={centerValue} subtitle={name} data={formattedData} name="Transactions" legend="off" />
        :
        <EmptyChart />
      }
    </Fragment>
  );
};

export default TotalTransactions;
