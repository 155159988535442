import './FilterChip.scss';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, TextField, List, ListItemControl, Checkbox, Button } from 'react-md';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { FUSE_OPTIONS, idFormatter, truncate, orderByKey } from '../../utils';

export class FilterChip extends Component {

  static propTypes = {
    direction: PropTypes.string, // open direction
    filterKey: PropTypes.string, // name of parent filter state property
    label: PropTypes.string.isRequired, //  Title displayed in filter
    labelKey: PropTypes.string, // property key to display the item name, sends (value, filterKey), defaults to 'name'
    onApply: PropTypes.func, // method to pass values to parent sends (value, filterKey)
    onRemove: PropTypes.func //optional function executed after onApply if remove button is clicked
  }

  static defaultProps = {
    direction: 'right',
    labelKey: 'name'
  }

  constructor(props) {
    super(props);
    this._element = React.createRef();
  }

  state = {
    expanded: false,
    initialData: [],
    searchTerm: ''
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);

    if (this.props.data) {
      this.updateState(this.props.data);
    }

    //shameful hack - style container to prevent break in flow
    const containerElement = this._element.current.parentElement.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data !== prevProps.data) {
      this.updateState(this.props.data);
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  updateState = (data) => {
    this.setState({
      initialData: data,
    });
  }

  handleClick = (e) => {
    if (this._element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      this.selectAll();
    }

    this.setState({
      expanded: false,
      searchTerm: ''
    });
  }

  countChecked = () => {
    const initialData = this.state.initialData;
    const checkedOptions = _.filter(initialData, { selected: true }).length;

    if (checkedOptions === initialData.length) {
      return 'all';
    } else {
      return checkedOptions;
    }
  }

  selectAll = () => this.setState({
    initialData: this.state.initialData.map(({ name, id }) => ({ name, id, selected: true }))
  });

  deselectAll = () => this.setState({
    initialData: this.state.initialData.map(({ name, id }) => ({ name, id, selected: false }))
  });

  isRemovable = () => {
    const { initialData } = this.state;
    return _.filter(initialData, { selected: true }).length < initialData.length;
  }

  isActive = () => {
    const { initialData } = this.state;
    return _.filter(initialData, { selected: true }).length < initialData.length;
  }

  handleSetExpand = (value, e) => {
    e.stopPropagation();
    this.setState({ expanded: value });
  }

  handleApply = (value, e) => {
    e.stopPropagation();

    const { onApply, filterKey } = this.props;

    this.setState({
      expanded: value
    });

    const selected = this.state.initialData;
    let applied = selected.length ? selected : null;
    onApply(applied, filterKey);
  }

  handleRemove = (e) => {
    e.stopPropagation();
    const { onApply, onRemove, filterKey } = this.props;

    this.setState({
      initialData: this.state.initialData.map(({ name, id }) => ({ name, id, selected: true }))
    }, () => {
      const selected = this.state.initialData;
      onApply(selected, filterKey);
    });

    if (onRemove) {
      this.props.onRemove(null, filterKey);
    }
  }

  handleOnChangeCheckbox = (v, item) => {
    const { initialData } = this.state;
    const index = _.findIndex(initialData, obj => obj.id === item.id);

    initialData[index].selected = v;
    this.setState({ initialData });
  }

  handleOnChangeSelectAll = v => v ? this.selectAll() : this.deselectAll();

  isAllSelected = () => {
    const { initialData } = this.state;
    const containsCheckedFalse = _.some(initialData, ['selected', false]);

    return !containsCheckedFalse;
  }

  createFilteredList = () => {
    const { initialData, searchTerm } = this.state;
    const searchList = initialData;
    const fuse = new Fuse(searchList, FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const filteredWithCheckState = results.map(result => _.find(initialData, ['id', result.id]));

    return searchTerm === '' ? initialData : filteredWithCheckState;
  }

  handleSearch = (searchTerm) => {
    this.setState({ searchTerm });
  }

  render() {
    const { label, direction, labelKey } = this.props;
    const { expanded } = this.state;
    const filteredList = orderByKey(this.createFilteredList(), 'name', 'asc');

    return (
      <Fragment>
        {!expanded &&
          <div ref={this._element} id={label}>
            <div className={`filter-chip ${this.isRemovable() ? 'removable' : ''}`} onClick={(e) => this.handleSetExpand(true, e)}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {`${label} (${this.countChecked()})`}
                </span>
                <Fragment>
                  {this.isRemovable() &&
                    <button id={`${idFormatter(label)}-filter-cancel`} className="chip-remove-button" onClick={(e) => this.handleRemove(e)}>
                      <FontIcon>cancel</FontIcon>
                    </button>
                  }
                  {!expanded && !this.isRemovable() &&
                    <span id={`${idFormatter(label)}-filter-drop-down`} className="chip-icon">
                      <FontIcon>arrow_drop_down</FontIcon>
                    </span>
                  }
                </Fragment>
              </div>
            </div>
          </div>
        }
        {expanded &&
          <div ref={this._element} id={label}>
            <div className={`filter-chip expanded expand-${direction}`}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {label}
                </span>
                <button id={`${idFormatter(label)}-filter-close-chip`} className="chip-close-icon" onClick={(e) => this.handleSetExpand(false, e)}>
                  <FontIcon>close</FontIcon>
                </button>
              </div>
              <div className="filter-chip-dropdown">
                <h5 className="chip-section-header">Search</h5>
                <TextField
                  id={`${idFormatter(label)}-search-input`}
                  placeholder="What are you looking for...?"
                  rightIcon={<FontIcon>search</FontIcon>}
                  fullWidth
                  onChange={(v) => this.handleSearch(v)}
                />
                <br />
                {!_.isEmpty(filteredList) &&
                  <Checkbox
                    id={`${idFormatter(label)}-top-control-select`}
                    className="ph-checkbox"
                    label="Select all"
                    name="select-all"
                    labelBefore
                    onChange={(v) => this.handleOnChangeSelectAll(v)}
                    checked={this.isAllSelected()}
                  />
                }
                <div className="list-container">
                  <List className="chip-list">
                    {filteredList && filteredList.map((item, index) => (
                      <ListItemControl
                        key={index}
                        secondaryAction={(
                          <Checkbox
                            id={`${idFormatter(label)}-check-input-${index}`}
                            className="ph-checkbox"
                            name={`${idFormatter(label)}-check-input-${index}`}
                            label={truncate(item[labelKey], 42)}
                            title={item[labelKey]}
                            onChange={(v) => this.handleOnChangeCheckbox(v, item)}
                            checked={item.selected}
                            labelBefore
                          />
                        )}
                      />
                    ))}
                  </List>
                </div>
                <br />
                <div className="row end-xs">
                  <div className="col-xs">
                    <Button
                      disabled={filteredList.length === 0}
                      id={`${idFormatter(label)}-filter-apply`}
                      flat
                      secondary
                      swapTheming
                      onClick={(e) => this.handleApply(false, e)}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}
