import React, { Fragment } from 'react';
import { DonutChart, EmptyChart } from '../../components';
import { isNotEmpty, percentFormat } from '../../utils';

const VipsParked = (props) => {
  const data = props.data;
  const dataAvailable = isNotEmpty(data) && (data.value > 0);
  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      if (data.parked) {
        formattedArray.push({
          name: 'Parked',
          y: data.parked,
          color: '#FBD648',
          selected: true
        });
      }

      if (data.available > data.parked) {
        const available = data.available - data.parked;

        formattedArray.push({
          name: 'Available',
          y: available,
          color: '#EAEBEC',
          selected: true
        });
      }

      return formattedArray;
    })();
  }

  return (
    <Fragment>
      {
        dataAvailable ?
          <DonutChart
            data={formattedData}
            name="Spots"
            subtitle="Utilization"
            total={percentFormat(data.value, 0)}
          />
          :
          <EmptyChart />
      }
    </Fragment>
  );
};

export default VipsParked;
