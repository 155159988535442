import React from 'react';
import { LineChart } from '../';
import { EmptyChart } from '../';
import _ from 'lodash';
import { ISOtoMilli, userTimezoneOffsetMilli  } from '../../utils';

const Activity = ({ data = {} }) => {
  const dataAvailable = data.timeSeriesData.length > 0;
  
  let formattedData = [];

  if (dataAvailable) {
    const timeSeries = data.timeSeriesData.map(item => ({
      ...item,
      name: ISOtoMilli(item.name) - userTimezoneOffsetMilli,
    }));

    formattedData = _.orderBy(timeSeries, [o => o.name], 'asc').map(x => {
      const entry = [];
      entry.push(x.name, x.value);

      return entry;
    });
  }

  return (
    <>
      {dataAvailable ?
        <LineChart data={[{ name: 'Parked', data: formattedData, color: '#FF8287' }]} total={data.value} /> :
        <EmptyChart />
      }
    </>
  );
};

export default Activity;
