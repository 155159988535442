import React from 'react';
import { TopMetric, ChartCard, Activity, RevenueByType } from '../../components';
import { CashIcon, DirectionsCarIcon } from '../../assets/react-icons';
import { isNotEmpty } from '../../utils';
import Odometer from 'react-odometerjs';

const Section1 = ({ data }) => {

  return (
    <div className="row chart-row">
      <div className="col-xs-12 col-sm-6">
        <ChartCard>
          {data && isNotEmpty(data.parkingRevenue) &&
            <>
              <TopMetric
                className="large"
                value={
                  <>
                    <span className="top-metric-prefix">$</span>
                    <Odometer duration={100} value={data.parkingRevenue.value / 100} format="(,ddd)" />
                  </>
                }
                label="On-site Revenue"
                icon={<CashIcon width="25" color="#8BC34A" />}
              />
              <RevenueByType data={data.parkingRevenue} />
            </>
          }
        </ChartCard>
      </div>
      <div className="col-xs-12 col-sm-6">
        <ChartCard>
          {data && isNotEmpty(data.parked) &&
            <>
              <TopMetric
                className="large"
                value={
                  <Odometer duration={100} value={data.parked.value} format="(,ddd)" />
                }
                label="Parked"
                icon={<DirectionsCarIcon width="25" color="#FF8354" />}
              />
              <Activity data={data.parked} />
            </>
          }
        </ChartCard>
      </div>
    </div>
  );
};

export default Section1;
