import './BasicDialog.scss';
import React from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';

const BasicDialog = ({ visible, pageX, pageY, onHide, color, textColor, title, children }) => {

  const _color = color || '#FF5722';

  return (
    <DialogContainer
      id="basic-dialog"
      className="focus-dialog"
      visible={visible}
      pageX={pageX}
      pageY={pageY}
      fullPage
      aria-labelledby={title || 'basic-dialog'}
      disableScrollLocking
      lastChild
      portal
    >
      <Toolbar
        fixed
        colored
        title={title || ''}
        nav={<Button id="dialog-back-button" style={{ color: textColor }} icon onClick={onHide}>arrow_back</Button>}
        style={{ backgroundColor: _color, color: textColor }}
      />
      <section>
        <div className="wrapper">
          <div className="col-xs-12">
            <div className="dialog-action-container" />
          </div>
          <div className="row">
            <div className="col-xs-12">
              {children}
            </div>
          </div>
        </div>
      </section>
    </DialogContainer>
  );
};

export default BasicDialog;
