import './EmptyChart.scss';
import React from 'react';
import { SearchIcon } from '../../assets/react-icons';

const EmptyChart = () => {
  return (
    <div className="empty-chart-container">
      <SearchIcon color="#BBBBBB" height="50px" />
      <div className="empty-chart-text">
        No data matches<br />these parameters
      </div>
    </div>
  );
};

export default EmptyChart;
