import './DonutChart.scss';
import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, Chart, withHighcharts, Title, PieSeries, Subtitle, Tooltip } from 'react-jsx-highcharts';
import { CHART_COLORS } from '../../utils/chartconfig';

const DonutChart = ({ colors, data, name, subtitle, total }) => {

  return (
    <Fragment>
      <HighchartsChart chart={{ height: '140px' }} className="donut-chart">
        <Chart />
        <Tooltip
          backgroundColor="white"
        />
        <Title
          align="center"
          className="donut-title"
          verticalAlign="middle"
          y={-5}
          style={{
            fontSize: '18px'
          }}
        >
          {total}
        </Title>
        <Subtitle
          align="center"
          className="donut-subtitle"
          verticalAlign="middle"
          y={15}
          style={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.55)'
          }}
        >
          {subtitle || ''}
        </Subtitle>
        <PieSeries
          borderWidth={0}
          center={['50%', '50%']}
          colors={colors ? colors : CHART_COLORS}
          data={data}
          dataLabels={{ enabled: false }}
          depth={3}
          id="pie-series"
          innerSize="85%"
          name={name}
          size="130px"
        />
      </HighchartsChart>
    </Fragment>
  );
};

export default withHighcharts(DonutChart, Highcharts);
