import React, { Fragment } from 'react';
import {
  DonutChart,
  EmptyChart } from '../';
import {
  percentFormat,
  titleCase
} from '../../utils/';
import _ from 'lodash';

const CreditRevenue = (props) => {
  const data = props.data;
  const dataAvailable = data.value > 0;

  const colors = {
    visa: '#6543bb',
    mastercard: '#bd4dab',
    amex: '#fd549f',
    discover: '#ff8187',
    other: '#ffc565',
  };

  let formattedData = [];
  let name = '';
  let centerValue = 0;

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      for (let x in colors) {
        if (data.hasOwnProperty(x)) {
          formattedArray.push({
            name: titleCase(x),
            y: data[x],
            color: colors[x],
            selected: true
          });
        };
      };
      return formattedArray;
    })();

    const highestValue = getHighestValue(formattedData);
    name = highestValue.name;
    centerValue = percentFormat(highestValue.y / getTotal(formattedData)  * 100, 0);
  }

  function getHighestValue(list) {
    return _.maxBy(list, (o) => o.y);
  }

  function getTotal(list) {
    return list.map(o => o.y).reduce((a, b) => a + b, 0);
  }

  return (
    <Fragment>
      {dataAvailable ?
        <DonutChart total={centerValue} name="transactions" subtitle={titleCase(name)} data={formattedData} />
        :
        <EmptyChart />
      }
    </Fragment>
  );
};

export default CreditRevenue;
