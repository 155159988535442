import React, { Fragment, useState } from 'react';
import {
  ChartCard,
  MetricListCashiers,
  MetricListTransactions,
  MetricListLots,
  BasicDialog,
  EmptyChart
} from '../../components';
import { Button } from 'react-md';
import { isNotEmpty } from '../../utils';

const DialogButton = ({ label, onClick }) => {
  return (
    <Button
      className="button ghost center"
      flat
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const Section3 = ({ metrics, transactions }) => {
  const listLimit = 5;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialog, setDialog] = useState({
    dialogTitle: '',
    pageX: null,
    pageY: null,
    dialogComponent: ''
  });

  const animationTimeout = 300;

  function createCashiers(data) {
    const { lotOverviews, cashierOverviews } = data;
    const lotMap = new Map();

    lotOverviews.forEach(lot => {
      if (lot.cashiers.length) {
        lot.cashiers.forEach(cashier => {
          lotMap.set(cashier, lot.name);
        });
      }
    });

    const cashiersList = cashierOverviews.map(cashier => {
      return {
        ...cashier,
        lotName: lotMap.get(cashier.id)
      };
    });

    return cashiersList;
  };

  function showDialog(e, dialogComponent, dialogTitle) {
    let { pageX, pageY } = e;

    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }
    setDialogVisible(true);

    setDialog({
      dialogTitle,
      dialogComponent,
      pageX,
      pageY
    });
  };

  function hideDialog() {
    setDialogVisible(false);
  };

  return (
    <Fragment>
      <div className="row chart-row">
        <div className="col-xs-12 col-sm-4">
          <ChartCard className="list" label="Cashiers">
            {isNotEmpty(metrics.cashierOverviews) ?
              <Fragment>
                <MetricListCashiers
                  listLimit={listLimit}
                  textLength={12}
                  data={createCashiers(metrics)}
                  animationTimeout={animationTimeout}
                />
                <div className="chard-card-action-container">
                  {metrics.cashierOverviews.length >= listLimit && (
                    <DialogButton
                      label="View more cashiers"
                      onClick={e => showDialog(e, 'cashiers', 'Cashiers')}
                    />
                  )}
                </div>
              </Fragment>
              : <EmptyChart />
            }
          </ChartCard>
        </div>
        <div className="col-xs-12 col-sm-4">
          <ChartCard className="list" label="Lots/Garages">
            {isNotEmpty(metrics.lotOverviews) ?
              <Fragment>
                <MetricListLots
                  listLimit={listLimit}
                  textLength={12}
                  data={metrics.lotOverviews}
                  animationTimeout={animationTimeout}
                />
                <div className="chard-card-action-container">
                  {metrics.lotOverviews.length >= listLimit && (
                    <DialogButton
                      label="View more lots / garages"
                      onClick={e => showDialog(e, 'lots', 'Lots / Garages')}
                    />
                  )}
                </div>
              </Fragment>
              : <EmptyChart />
            }
          </ChartCard>
        </div>
        <div className="col-xs-12 col-sm-4">
          <ChartCard className="list" label="Recent transactions">
            {isNotEmpty(transactions) ?
              <Fragment>
                <MetricListTransactions
                  listLimit={listLimit}
                  data={transactions}
                  animationTimeout={animationTimeout}
                />
                <div className="chard-card-action-container">
                  {transactions.length >= listLimit && (
                    <DialogButton
                      label="View more transactions"
                      onClick={e => showDialog(e, 'transactions', 'Recent Transactions')}
                    />
                  )}
                </div>
              </Fragment>
              : <EmptyChart />
            }
          </ChartCard>
        </div>
      </div>
      <BasicDialog
        visible={dialogVisible}
        pageX={dialog.pageX}
        pageY={dialog.pageY}
        onHide={hideDialog}
        title={dialog.dialogTitle}
        color="#FFFFFF"
        textColor="#2D3843"
      >
        <ChartCard>
          {isNotEmpty(metrics.cashierOverviews) && dialog.dialogComponent === 'cashiers' &&
            <MetricListCashiers
              textLength={100}
              data={createCashiers(metrics)}
              animationTimeout={animationTimeout}
            />
          }
          {isNotEmpty(metrics.lotOverviews) && dialog.dialogComponent === 'lots' &&
            <MetricListLots
              textLength={100}
              data={metrics.lotOverviews}
              animationTimeout={animationTimeout}
            />
          }
          {isNotEmpty(transactions) && dialog.dialogComponent === 'transactions' &&
            <MetricListTransactions
              data={transactions}
              animationTimeout={animationTimeout}
            />
          }
        </ChartCard>
      </BasicDialog>
    </Fragment>
  );
};

export default Section3;
