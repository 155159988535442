import './FilterBar.scss';
import React from 'react';
import _ from 'lodash';

const FilterBar = ({ children, className }) => {

  const renderChildren = () => {
    if (_.isArray(children)) {
      return children.map((child, index) => {
        if (typeof child !== 'undefined' && child !== false) {
          return (
            <div key={index} className="filter-chip-container">
              {child}
            </div>
          );
        }
        return null;
      });
    } else {
      return (
        <div className="filter-chip-container">
          {children}
        </div>
      );
    }
  };

  return (
    <div className={`filter-bar ${className ? className : ''}`}>
      {renderChildren()}
    </div>
  );

};

export default FilterBar;
