import ParkhubLogo from '../../assets/parkhub-live-logo-black.svg';
import './Layout.scss';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Overview from '../Overview/Overview';
import { Header } from '@parkhub/parkhub-ui';
import { signOut } from '../../utils';

const Layout = props => {
  return (
    <>
      <Header
        authUser={props.authUser}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={152}
        appMenuActive
        logoClick={()=> props.history.push('/')}
      />
      <main id="layout">
        <div className="wrapper">
          <Switch>
            <Route exact path="/" render={() => <Overview {...props} />} />
          </Switch>
        </div>
      </main>
    </>
  );
};

export default Layout;
