import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import { checkAuth, getAuthUser, getSessionTime, refreshSession, signOut } from './utils';

const App = ({ history }) => {
  const checkTime = useRef(false);
  const [authUser, setAuthUser] = useState();

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);

          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              signOut();

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                signOut('?expired=true');
              } else if (minutes > 10 && minutes < 1440) {
                refreshSession();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, [],
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
      } else {
        startTimeout();
        setAuthUser(getAuthUser());
      }
    }, [startTimeout],
  );

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  return (
    <>
      {!!authUser &&
        <Layout authUser={authUser} history={history} />
      }
    </>
  );
};

export default withRouter(App);
