import './MetricList.scss';
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PersonIconCircled } from '../../assets/react-icons';
import { currencyFormat, numberWithCommas, truncate, limitListSize } from '../../utils';

const MetricListCashiers = ({ data = [], listLimit = 0, textLength = 0, animationTimeout = 0 }) => {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (listLimit) {
      setListData(limitListSize(data, listLimit));
    } else {
      setListData(data);
    }
  }, [data, listLimit]);

  return (
    <div className="metric-list">
      <table>
        <TransitionGroup component="tbody">
          {listData.map(item => {
            const { id = '', lotName = '', name = '', totalCashRevenue = 0, transactions = 0 } = item;
            return (
              <CSSTransition
                key={id}
                timeout={animationTimeout}
                classNames="fade"
              >
                <tr key={id} className="metric-list-row">
                  <td className="metric-list-column">
                    <PersonIconCircled width="32" color="#2196F3" />
                  </td>
                  <td className="metric-list-column">
                    <span title={name} className="metric-list-cell-top">{truncate(name, textLength)}</span>
                    <span title={lotName} className="metric-list-cell-bottom">{truncate(lotName, textLength)}</span>
                  </td>
                  <td className="metric-list-column">
                    <span className="metric-list-cell-top">{currencyFormat(totalCashRevenue)}</span>
                    <span className="metric-list-cell-bottom">Total cash</span>
                  </td>
                  <td className="metric-list-column">
                    <span className="metric-list-cell-top">{numberWithCommas(transactions)}</span>
                    <span className="metric-list-cell-bottom">Transactions</span>
                  </td>
                </tr>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListCashiers;
