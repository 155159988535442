import React from 'react';
import Odometer from 'react-odometerjs';
import {
  ChartCard,
  TopMetric,
  TotalTransactions,
  Utilization,
  CreditRevenue,
  VipsParked
} from '../../components';
import { isNotEmpty } from '../../utils';
import { TransactionIcon, CreditCardIcon, Star, DataUsage } from '../../assets/react-icons';

const Section2 = ({ data }) => {

  return (
    <div className="row chart-row">
      <div className="col-xs-12 col-sm-3">
        <ChartCard>
          {data && isNotEmpty(data.totalTransactions) &&
            <>
              <TopMetric
                value={
                  <Odometer duration={100} value={data.totalTransactions.value} format="(,ddd)" />
                }
                label="Transactions"
                icon={<TransactionIcon width="25" color="#2D3843" />}
              />
              <br />
              <TotalTransactions data={data.totalTransactions} />
            </>
          }
        </ChartCard>
      </div>
      <div className="col-xs-12 col-sm-3">
        <ChartCard>
          {data && isNotEmpty(data.utilization) &&
            <>
              <TopMetric
                value={
                  <>
                    <Odometer duration={100} value={data.utilization.parked} format="(,ddd)" />
                    <span className="top-metric-prefix">/</span>
                    <Odometer duration={100} value={data.utilization.available} format="(,ddd)" />
                  </>
                }
                label="Utilization"
                icon={<DataUsage width="25" color="#FF8354" />}
              />
              <br />
              <Utilization data={data.utilization} />
            </>
          }
        </ChartCard>
      </div>
      <div className="col-xs-12 col-sm-3">
        <ChartCard>
          {data && isNotEmpty(data.creditBreakdown) &&
            <>
              <TopMetric
                value={
                  <>
                    <span className="top-metric-prefix">$</span><Odometer duration={100} value={data.creditBreakdown.value / 100} format="(,ddd)" />
                  </>
                }
                label="Credit Revenue"
                icon={<CreditCardIcon width="25" color="#29B6F6" />}
              />
              <br />
              <CreditRevenue data={data.creditBreakdown} />
            </>
          }
        </ChartCard>
      </div>
      <div className="col-xs-12 col-sm-3">
        <ChartCard>
          {data && isNotEmpty(data.vipsParked) &&
            <>
              <TopMetric
                value={
                  <>
                    <Odometer duration={100} value={data.vipsParked.parked} format="(,ddd)" />
                    <span className="top-metric-prefix">/</span>
                    <Odometer duration={100} value={data.vipsParked.available} format="(,ddd)" />
                  </>
                }
                label="VIPs Parked"
                icon={<Star width="25" color="#FBD648" />}
              />
              <br />
              <VipsParked data={data.vipsParked} />
            </>
          }
        </ChartCard>
      </div>
    </div>
  );
};

export default Section2;
