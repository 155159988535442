import './MetricList.scss';
import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import moment from 'moment';
import { HighlightOffIcon, ErrorOutlineIcon, CircleCheckIcon } from '../../assets/react-icons';
import { limitListSize } from '../../utils';

const MetricListTransactions = (props) => {
  const { data, listLimit, animationTimeout } = props;
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (listLimit) {
      setListData(limitListSize(data, listLimit));
    } else {
      setListData(data);
    }
  }, [data, listLimit]);

  return (
    <div className="metric-list">
      <table>
        <TransitionGroup component="tbody">
          {listData.map((item, index) => (
            <CSSTransition
              key={index}
              timeout={animationTimeout}
              classNames="fade"
            >
              <tr key={index} className="metric-list-row">
                <td className="metric-list-column">
                  {item.status === 'failed' ? <HighlightOffIcon color="#EF5350" width={24} />
                    : item.status === 'pending' ? <ErrorOutlineIcon color="#FDD835" width={24} />
                      : item.status === 'successful' && <CircleCheckIcon color="#00C853" width={24} />}
                </td>
                <td className="metric-list-column">
                  <span className="metric-list-cell-top">{item.status}</span>
                  <span className="metric-list-cell-bottom">{item.user_name}</span>
                </td>
                <td className="metric-list-column">
                  <span className="metric-list-cell-top">{item.pricing_name}</span>
                  <span className="metric-list-cell-bottom">Parking Type</span>
                </td>
                <td className="metric-list-column">
                  <span className="metric-list-cell-top no-cap">{moment(item.transaction_time).format('h:mm a')}</span>
                  <span className="metric-list-cell-bottom no-cap">{moment(item.transaction_time).fromNow()}</span>
                </td>
              </tr>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListTransactions;
