import React from 'react';

const TopMetric = ({ value, label, icon, className }) => {

  return (
    <div className={`top-metric ${className ? className : ''}`}>
      <h2 className="top-metric-value">
        {value}
      </h2>
      <p className="top-metric-label">
        {label}
      </p>
      <span className="top-metric-icon">
        {icon}
      </span>
    </div>
  );
};

export default TopMetric;
