import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, Chart, withHighcharts, LineSeries, YAxis, XAxis, Tooltip } from 'react-jsx-highcharts';
import { userTimezoneName } from '../../utils/';

const LineChart = ({ data: chartData = [] }) => {

  const markerOptions = {
    fillColor: '#FFFFFF',
    lineWidth: 2,
    lineColor: null,
    symbol: 'circle'
  };

  const chartLines = () => chartData.map(series => {
    const { color = '', data: seriesData = [], name = '' } = series;
    const key = `${name}-series`;
    
    return (
      <LineSeries
        key={key}
        name={name}
        color={color}
        marker={markerOptions}
        data={seriesData}
        lineWidth={2}
      />
    );
  });

  return (
    <>
      <HighchartsChart
        chart={{
          height: '140px'
        }}
        className="line-chart"
      >
        <Chart />
        <Tooltip
          backgroundColor="white"
          xDateFormat={`%a., %b %e, %l:%M%P ${userTimezoneName}`}
        />
        <XAxis
          type="datetime"
          gridLineWidth="1px"
          labels={{
            style: {
              'color': '#666666', 'font-size': '14px'
            }
          }}
        />
        <YAxis
          gridLineWidth="0px"
          labels={{
            enabled: false
          }}
        >
          {chartLines()}
        </YAxis>
      </HighchartsChart>
    </>
  );
};

export default withHighcharts(LineChart, Highcharts);
