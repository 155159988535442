import React, { Fragment } from 'react';
import { DonutChart, EmptyChart } from '../';
import { percentFormat } from '../../utils';

const Utilization = (props) => {
  const data = props.data;
  const dataAvailable = data.value > 0;
  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      if (data.parked) {
        formattedArray.push({
          name: 'Parked',
          y: parseInt(data.parked, 10),
          color: '#FF8287',
          selected: true
        });
      }

      if (data.available > data.parked) {
        const available = data.available - data.parked;

        formattedArray.push({
          name: 'Available',
          y: parseInt(available, 10),
          color: '#EAEBEC',
          selected: true
        });
      }

      return formattedArray;
    })();
  }

  return (
    <Fragment>
      {
        dataAvailable ?
          <DonutChart
            data={formattedData}
            name="Spots"
            subtitle="Utilized"
            total={percentFormat(data.value, 0)}
          />
          :
          <EmptyChart />
      }
    </Fragment>
  );
};

export default Utilization;
