import './MetricList.scss';
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { QuadIconCircled } from '../../assets/react-icons';
import { currencyFormat, percentFormat, truncate, limitListSize } from '../../utils';

const MetricListLots = (props) => {
  const { data, listLimit, textLength, animationTimeout } = props;
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (listLimit) {
      setListData(limitListSize(data, listLimit));
    } else {
      setListData(data);
    }
  }, [data, listLimit]);

  return (
    <div className="metric-list">
      <table>
        <TransitionGroup component="tbody">
          {listData.map((item, index) => (
            <CSSTransition
              key={index}
              timeout={animationTimeout}
              classNames="fade"
            >
              <tr key={index} className="metric-list-row">
                <td className="metric-list-column">
                  <QuadIconCircled width="32" color="#737373" />
                </td>
                <td className="metric-list-column">
                  <span title={item.name} className="metric-list-cell-top">{truncate(item.name, textLength)}</span>
                  <span className="metric-list-cell-bottom">Lot name</span>
                </td>
                <td className="metric-list-column">
                  <span className="metric-list-cell-top">{currencyFormat(item.revenue)}</span>
                  <span className="metric-list-cell-bottom">Total revenue</span>
                </td>
                <td className="metric-list-column">
                  <span className="metric-list-cell-top no-cap">{percentFormat(item.utilization)}</span>
                  <span className="metric-list-cell-bottom no-cap">Utilization</span>
                </td>
              </tr>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListLots;
