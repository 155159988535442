import axios from 'axios';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { CHART_COLORS } from './chartconfig';
import { FUSE_OPTIONS } from './fuseOptions';

const cookies = new Cookies();
const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export { FUSE_OPTIONS, CHART_COLORS };

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

// update auth cookie
export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');

  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

// Sign out user and redirect to suite login
export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

export const idFormatter = (str) => str.toLowerCase().split(' ').join('-');

// number formatting
export const numberWithCommas = x => {
  const num = Number.isInteger(x) ? x : parseFloat(x.toFixed(1));
  return `${num.toLocaleString()}`;
};

export const currencyFormat = x => {
  const num = parseFloat(x.toFixed(0) / 100);
  return `$${num.toLocaleString()}`;
};

export function percentFormat(value, numOfDigits) {
  const digits = (numOfDigits === 0 || numOfDigits) ? numOfDigits : (Number.isInteger(value)) ? 0 : 1;
  return parseFloat((value)).toFixed(digits) + '%';
}

export const ISOtoMilli = isoDate => new Date(isoDate).getTime();

export const userTimezoneName = new Intl.DateTimeFormat('default',
  { timeZoneName: 'short' })
  .format(new Date())
  .split(',')[1]
  .padStart();

export const minutesToMilli = (minutes = 0) => minutes * 60000;

export const userTimezoneOffsetMilli = minutesToMilli(new Date().getTimezoneOffset());

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function limitListSize(data, limit) {
  return (data.length > limit) ? data.slice(0, limit) : data;
}

export function deleteCookies() {
  cookies.remove('86553b39', {
    domain: '.parkhub.com'
  });
  cookies.remove('accessToken', {
    domain: '.parkhub.com'
  });
  cookies.remove('_gtmUID', {
    domain: '.parkhub.com'
  });
}

function checkForEnvMatch() {
  if (getCookie('86553b39')) {
    return getAuthUser().ne === process.env.REACT_APP_ENV_TAG;
  } else {
    return false;
  }
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');

  if (authCookie && authCookie !== 'undefined' && checkForEnvMatch()) {
    return true;

  } else {
    deleteCookies();
    return false;
  }
};

export function getAuthUser() {
  return jwt.decode(getCookie('86553b39'), { complete: true }).payload.payload;
}

export function getSessionTime() {
  const iat = jwt.decode(getCookie('86553b39'), { complete: true }).payload.iat;
  return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
}
